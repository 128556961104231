.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-modal {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  /* align-items: ${(props) => props.alignItems || 'flex-end'}; */
  z-index: 999999999999;
  flex-direction: column;
}

.modal {
  height: 83.7333vw;
  width: 100vw;
  position: absolute;
  bottom: 65vw;
  z-index: 100000;
  border: 1px solid #EFEFEF;
  border-radius: 5.6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  text-align: center;
  
}

.buttonAdd {
  width: 76vw;
  height: 12vw;
  border-radius: 2.666vw;
  background-color: #000000;
  font-family: 'Outfit', sans-serif;
  font-size: 3.7333vw;
  border: none;
  font-weight: 700;
  color: #FFFFFF;
  margin: 6.37333vw 0 0 0;
}
.text {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  font-size: 4.8vw;
  width: 76.2666vw;
}
.x-icon {
  width: 3.9893vw;
  height: 3.9893vw;
  position: absolute;
  top: 5.333vw;
  right: 5.333vw;
}
.logo-icon {
  width: 21.333vw;
  height: 21.333vw;
  border-radius: 3.7333vw;
  margin: 13.866vw 0 5.333vw 0;
}
